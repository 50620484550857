import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import StoreContext from '~/context/StoreContext'
import { DrawerModal } from '~/components/Dialog/drawer'
import DrawerLineItem from '~/components/Cart/DrawerLineItem'
import LoaderIcon from '~/images/svg/loader-icon.svg'

const CartDrawer = () => {
    const {
        removeCartError,
        store: { cart, adding, cartError }
    } = useContext(StoreContext)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const items = cart?.lines?.edges ?? []
    const totalItems = items.reduce((acc, item) => acc + item.node.quantity, 0)

    useEffect(() => {
        if (adding && !drawerOpen) {
            setDrawerOpen(true)
            // buttonRef.current?.focus();
        }
    }, [drawerOpen, adding]);

    const subtotalPrice = Intl.NumberFormat(undefined, {
        currency: cart?.cost?.subtotalAmount?.currencyCode ?? 'USD',
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(parseFloat(cart?.cost?.subtotalAmount?.amount ?? 0))

    const closeDrawer = () => {
        setDrawerOpen(false)
        setTimeout(() => removeCartError(), 500);
    }

    // const buttonRef = useRef(null);

    return (
        <DrawerModal isOpen={drawerOpen} setIsOpen={closeDrawer}>
            <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                    <p id="drawerLabel" className="text-sm font-bold text-rich-black">
                        Shopping Cart {totalItems > 0 ? <span>({totalItems})</span> : null}
                    </p>
                    <div className="ml-3 h-7 flex items-center">
                        <button
                            aria-label="Close panel"
                            className="bg-white text-gray-400 hover:text-gray-500"
                            onClick={closeDrawer}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g transform="translate(-1730 -101)">
                                    <g transform="translate(7 -45)">
                                        <circle cx="9" cy="9" r="9" transform="translate(1723 146)" fill="#0e141a" />
                                        <g transform="translate(1728 151)">
                                            <path d="M-15737.718,726.231a.708.708,0,0,1-.5-.208l-6.577-6.577a.712.712,0,0,1,0-1.006.711.711,0,0,1,1.006,0l6.577,6.577a.712.712,0,0,1,0,1.006A.711.711,0,0,1-15737.718,726.231Z" transform="translate(15745.006 -718.232)" fill="#fff" />
                                            <path d="M7.288,8a.708.708,0,0,1-.5-.208L.208,1.214A.711.711,0,0,1,1.214.208L7.791,6.786A.711.711,0,0,1,7.288,8Z" transform="translate(8 0.001) rotate(90)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="mt-3.5 pt-3.5 lg:pt-6 relative flex-1 mx-4 sm:mx-6 border-t border-platinum">
                {cartError ?
                    <div className="p-4 bg-red-50 mb-6 rounded-sm">
                        <p className="text-sm text-red-800"><span className="font-bold">Sorry, something went wrong.</span> {cartError}</p>
                    </div> : null
                }

                {items.map(item => (
                    <DrawerLineItem
                        key={item.node.id}
                        item={item.node}
                        setDrawerOpen={setDrawerOpen}
                    />
                ))}

                {items.length === 0 &&
                    <div className="text-center h-24 flex justify-center items-center">
                        <span className="mb-4 text-gray-400">Your cart is empty.</span>
                    </div>
                }

                {adding &&
                    <div className="absolute w-full h-full top-0 flex justify-center bg-white bg-opacity-60">
                        <LoaderIcon className="animate-spin h-10 w-10 mt-32" />
                    </div>
                }

                <div className="pb-4">
                    <div className="flex justify-between text-sm">
                        <p className="lg:mr-4 font-medium">Subtotal</p>
                        <p className="font-bold">{subtotalPrice !== '$NaN' ? subtotalPrice : '$0.00'}</p>
                    </div>
                    <p className="text-xs opacity-50 mt-1">Shipping and taxes are calculated at checkout.</p>
                </div>

                <Link
                    to="/cart/"
                    onClick={() => setDrawerOpen(false)}
                    // ref={buttonRef}
                    className="text-center font-mono h-[50px] block py-4 pt-[17px] px-6 xl:px-12 font-medium text-sm !leading-none transition-colors bg-rich-black text-white uppercase tracking-wide w-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rich-black"
                >
                    Review My Cart
                </Link>
                <button className="sr-only" onClick={() => setDrawerOpen(false)}>Close & Continue Shopping</button>
            </div>
        </DrawerModal>
    )
}


export default CartDrawer
