const cartFragment = `
  fragment cart on Cart {
    id
    createdAt
    updatedAt
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      subtotalAmountEstimated
      totalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalAmountEstimated
    }
    attributes {
      key
      value
    }
    lines(first: 150) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          mtoTimeframe: attribute(key: "Made-to-Order") {
            value
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url(transform: { maxWidth: 400, maxHeight: 400, crop: CENTER })
              }
              thumb: image {
                url(transform: { maxWidth: 200, maxHeight: 200, crop: CENTER })
              }
              price {
                amount
                currencyCode
              }
              product {
                id
                title
                handle
                tags
              }
            }
          }
        }
      }
    }
  }
`

export default cartFragment